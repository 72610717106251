import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, getAuth, signInWithPopup, GoogleAuthProvider, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";

const Sidebar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    // Pasang listener hanya sekali
    const unsubscribe = onAuthStateChanged(auth, (authData) => {
      if (authData) {
        setUser(authData);
      } else {
        setUser(null);
      }
    });

    // Bersihkan listener saat komponen dilepas
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    await setPersistence(auth, browserLocalPersistence);

    try {
      const result = await signInWithPopup(auth, provider);
      const userData = result.user;
      setUser({
        displayName: userData.displayName,
        email: userData.email,
        photoURL: userData.photoURL,
      });
      console.log("User logged in: ", userData);
    } catch (error) {
      console.error("Error during login: ", error);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      setUser(null);
      console.log("User logged out");
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  return (
    <div className="w-64 h-full bg-gray-800 text-white flex flex-col justify-between p-5">
      <div>
        <h2 className="text-2xl font-bold mb-8">Dashboard</h2>
        <ul>
          <li className="mb-4">
            <Link to="/" className="hover:bg-gray-700 p-2 block rounded">Dashboard</Link>
          </li>
          <li className="mb-4">
            <Link to="/batch-input" className="hover:bg-gray-700 p-2 block rounded">Batch input</Link>
          </li>
          <li className="mb-4 flex w-full">
            <Link to="/items" className="hover:bg-gray-700 p-2 block rounded flex w-full justify-between">
              <span>Items</span>
              <span className="text-xs bg-blue-400 text-black px-2 py-1 rounded">WIP</span>
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/minting-calculator" className="hover:bg-gray-700 p-2 block rounded">Minting Calculator</Link>
          </li>
        </ul>
      </div>

      <div>
        {user ? (
          <div className="flex flex-col items-center">
            <img
              src={user.photoURL}
              alt={user.displayName}
              className="w-12 h-12 rounded-full mb-2"
            />
            <p className="text-center mb-2">{user.displayName}</p>
            <button
              onClick={handleLogout}
              className="w-full py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        ) : (
          <button
            onClick={handleLogin}
            className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Login dengan Google
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
