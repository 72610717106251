// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Items from './pages/Items';

const App = () => {
  return (
    <Router>
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 bg-gray-100 p-5">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/batch-input" element={<div>Coming soon</div>} />
            <Route path="/items" element={<Items />} />
            <Route path="/minting-calculator" element={<div>Coming soon</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
