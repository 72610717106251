import React from 'react';

const DataTable = ({ data, onEditClick }) => {
  return (
    <table className="w-full text-left border-collapse">
      <thead>
        <tr className="text-center">
          <th className="py-2"></th>
          <th className="border-b-2 py-2">Tanggal</th>
          <th className="border-b-2 py-2">Terendah</th>
          <th className="border-b-2 py-2">Possible laku</th>
          <th className="border-b-2 py-2"></th> {/* Kolom untuk ikon edit */}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className="group text-center">
            {/* Indikator hover */}
            <td className="py-4 flex items-center space-x-2">
              {row.event && (
                <div className="flex">
                  <div className="w-2 h-2 bg-red-500 rounded-full relative">
                    {/* Tooltip */}
                    <div className="hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 absolute mr-2 right-full whitespace-nowrap z-10">
                      {row.event}
                    </div>
                  </div>
                </div>
              )}
            </td>
            <td className="border-b py-2">{row.tanggal}</td>
            <td className="border-b py-2">{row.lowest}</td>
            <td className="border-b py-2">{row.possible}</td>
            <td className="border-b py-2" onClick={() => onEditClick(row)}>
              <span className="invisible group-hover:visible cursor-pointer text-blue-500">
                ✏️
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
