import React, { useEffect, useState } from 'react';
import { collection, getDocs  } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Modal from '../components/Modal';

const Items = () => {
  const [itemList, setItemList] = useState([]);
  const [isAddDataModalOpen, setIsAddDataModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    inputName: "",
    inputImageUrl: "",
    isEditState: false
  });

  // Fungsi untuk menangani perubahan input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Reset Form
  const resetForm = () => {
    setFormData({
      inputName: "",
      inputImageUrl: "",
      isEditState: false
    });
  };

  // Fungsi untuk menangani pengiriman form
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Jika tidak ada error, kirim data form
    console.log(formData);
  };

  const fetchData = async () => {
    try {
      const itemsSnapshot = await getDocs(collection(db, 'items'));

      const listData = []; // untuk menampung list items (tanpa data chart)

      for (const itemDoc of itemsSnapshot.docs) {
        const itemData = itemDoc.data();
        listData.push({ id: itemDoc.id, name: itemData.name, imageUrl: itemData.imageUrl });
      }

      setItemList(listData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-6">
      {/* Modal Tambah Data */}
      <Modal isOpen={isAddDataModalOpen} title={formData.isEditState ? "Edit Data" : "Tambah Data"} onClose={() => { setIsAddDataModalOpen(false); resetForm() }}>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="inputName" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="inputName"
              name="inputName"
              value={formData.inputName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="inputimageUrl" className="block text-sm font-medium text-gray-700">Image URL</label>
            <input
              type="text"
              id="inputImageUrl"
              name="inputImageUrl"
              value={formData.inputImageUrl}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          {formData.inputImageUrl && (
            <div className="mb-4">
              <span className="block text-sm font-medium text-gray-700">Preview Image</span>
              <img src={formData.inputImageUrl} className="w-12 h-12 object-cover" />
            </div>
          )}
          <button type="submit" className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Submit</button>
        </form>
      </Modal>
      {/* End of Modal */}

      {/* Content */}
      <div className="bg-white p-6 rounded-lg row shadow-md flex-1">
        <div className="w-full flex justify-between">
          <span>Under construction</span>
          <button onClick={() => setIsAddDataModalOpen(true)} className="px-2 py-2 bg-blue-500 text-white rounded-lg">Tambah</button>
        </div>
        <table className="table-auto mt-4 w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 w-fit text-left">Image</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Image URL</th>
            </tr>
          </thead>
          <tbody>
            {itemList.map((item) => (
              <tr key={item.id} className="group hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2 w-fit">
                  <img src={item.imageUrl} alt={item.name} className="w-16 h-16 object-cover" />
                </td>
                <td className="border border-gray-300 px-4 py-2">{item.name}</td>
                <td className="border border-gray-300 px-4 py-2 ">
                  <div className="flex items-center justify-between">
                    <span>{item.imageUrl}</span>
                    <button
                      className="invisible group-hover:visible px-2 py-2 bg-blue-500 text-white rounded-lg"
                    >
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* End of Content */}
    </div>
  );
};

export default Items;