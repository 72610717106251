import React from 'react';
import { Line } from 'react-chartjs-2';

const PriceChart = ({ data }) => {
  return (
    <div className="h-[300px] md:h-[400px] lg:h-[500px]">
      <Line 
        data={data} 
        options={{
          maintainAspectRatio: false,
          responsive: true,
        }} 
      />
    </div>
  );
};

export default PriceChart;
