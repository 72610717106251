import React from 'react';

const Modal = ({ isOpen, title, children, onClose, onActionClick, actionText }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        {/* Flexbox untuk judul dan tombol */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          {actionText && (
            <button 
              onClick={onActionClick} 
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {actionText}
            </button>
          )}
        </div>

        {/* Konten modal */}
        {children}

        {/* Tombol Close */}
        <button 
          onClick={onClose} 
          className="mt-4 w-full py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
