import React, { useEffect, useState } from 'react';
import PriceChart from '../components/PriceChart'
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { collection, doc, getDocs, addDoc, setDoc, serverTimestamp, query, orderBy  } from 'firebase/firestore';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { db } from '../firebaseConfig';
import DataTable from '../components/DataTable';
import Modal from '../components/Modal';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const Dashboard = () => {
  const [user, setUser] = useState(null);

  const [chartData, setChartData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddDataModalOpen, setIsAddDataModalOpen] = useState(false);
  const [isEditState, setIsEditState] = useState(false);
  const [isEditId, setIsEditId] = useState(null);

  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);

  const [inputTanggal, setInputTanggal] = useState('');
  const [inputLowest, setInputLowest] = useState('');
  const [inputPossible, setInputPossible] = useState('');
  const [inputEvent, setInputEvent] = useState('');
  const [checkBoxEvent, setCheckboxEvent] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    // Pasang listener hanya sekali
    const unsubscribe = onAuthStateChanged(auth, (authData) => {
      if (authData) {
        console.log('User logged in:', authData);
        setUser(authData);
      } else {
        console.log('No user logged in');
        setUser(null);
      }
    });

    // Bersihkan listener saat komponen dilepas
    return () => unsubscribe();
  }, []);

  const fetchData = async () => {
    try {
      const itemsSnapshot = await getDocs(collection(db, 'items'));

      const listData = []; // untuk menampung list items (tanpa data chart)

      for (const itemDoc of itemsSnapshot.docs) {
        const itemData = itemDoc.data();
        listData.push({ id: itemDoc.id, name: itemData.name, imageUrl: itemData.imageUrl });
      }

      setItemList(listData);

      // Jika tidak ada item yang dipilih, pilih item pertama sebagai default
      loadDataForItem(listData[0]);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchSubcollection = async (itemId) => {
    const subcollectionRef = collection(db, "items", itemId, "data");
    const subcollectionSnapshot = await getDocs(query(subcollectionRef, orderBy('tanggal', 'asc')));
  
    const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    const labels = [];
    const lowest = [];
    const possible = [];
    for (const data of subcollectionData) {
      labels.push(data.tanggal);
      lowest.push(data.lowest);
      possible.push(data.possible);
    }
    
    setChartData({
      labels,
      datasets: [
        {
          label: "lowest",
          data: lowest,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
        {
          label: "possible laku",
          data: possible,
          fill: false,
          borderColor: 'rgb(50, 100, 150)',
          tension: 0.1,
        },
      ],
    })

    setSelectedItemData(subcollectionData);
  
  };

  const resetInput = () => {
    setInputTanggal('');
    setInputLowest('');
    setInputPossible('');
    setInputEvent('');
    setCheckboxEvent(false);
    setIsEditId(null);
    setIsEditState(false);
  }

  const loadDataForItem = (item) => {
    fetchSubcollection(item.id);
    setSelectedItem(item);
    setIsModalOpen(false); // Tutup modal setelah memilih item
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleEditTableClick = (item) => {
    if (user) {
      setInputTanggal(item.tanggal);
      setInputLowest(item.lowest);
      setInputPossible(item.possible);
      if (item.event) {
        setCheckboxEvent(true);
      }
      setInputEvent(item.event);
      setIsEditId(item.id);
      setIsEditState(true);
      setIsAddDataModalOpen(true);
    } else {
      console.log("login dulu bos");
    }
  }

  const handleAddDataClick = () => {
    if (user) {
      setIsAddDataModalOpen(true);
    } else {
      console.log('user not logged in');
    }
  };

  const handleInputNumber = (inputNum) => {
    return inputNum.replace(",", ".");
  };

  const handleAddDataSubmit = async (event) => {
    event.preventDefault();
  
    if (!inputTanggal || !inputLowest) {
      // Tampilkan pesan kesalahan jika tanggal atau nilai kosong
      console.error("Tanggal dan harga lowest harus diisi!");
      return;
    }
  
    try {
      // Ambil reference ke dokumen item yang dipilih
      const itemRef = doc(db, "items", selectedItem.id);
  
      // Ambil reference ke subkoleksi "data"
      const dataRef = collection(itemRef, "data");
  
      if (isEditState) {
        // Jika dalam kondisi edit, gunakan setDoc untuk update data
        const docRef = doc(dataRef, isEditId); // Asumsikan selectedItemData berisi data yang ingin diedit
        await setDoc(docRef, {
          tanggal: inputTanggal,
          lowest: handleInputNumber(inputLowest ?? ""),
          possible: handleInputNumber(inputPossible ?? ""),
          event: inputEvent ?? "",
          updatedAt: serverTimestamp(), // Timestamp jika ada perubahan
          updatedUser: user.uid,
          updatedUserName: user.displayName,
          updatedUserEmail: user.email,
        });
  
        console.log("Data berhasil diperbarui!");
      } else {
        // Jika dalam kondisi tambah data baru, gunakan addDoc
        await addDoc(dataRef, {
          tanggal: inputTanggal,
          lowest: handleInputNumber(inputLowest ?? ""),
          possible: handleInputNumber(inputPossible ?? ""),
          event: inputEvent ?? "",
          createdAt: serverTimestamp(),
          user: user.uid,
          userName: user.displayName,
          userEmail: user.email,
        });
        console.log("Data berhasil ditambahkan!");
      }
  
      // Reset form setelah berhasil
      resetInput();
  
      // Tutup modal setelah submit
      setIsAddDataModalOpen(false);
      loadDataForItem(selectedItem);
    } catch (error) {
      console.error("Error menambahkan data:", error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Dashboard - Nightcrows Market Price (Sea 104)</h1>

      {/* Dropdown-like button for selected item */}
      <div onClick={openModal} className="flex bg-white items-center space-x-2 mb-4 p-2 border rounded-lg cursor-pointer w-64">
        {selectedItem && (
          <>
            <img src={selectedItem.imageUrl} alt={selectedItem.name} className="w-8 h-8 rounded-full" />
            <span>{selectedItem.name}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z" clipRule="evenodd" />
            </svg>
          </>
        )}
      </div>

      {/* Modal Pilih Item */}
      <Modal isOpen={isModalOpen} title="Select an Item" onClose={() => setIsModalOpen(false)}>
        <div className="grid grid-cols-2 gap-4">
          {itemList.map((item) => (
            <div key={item.id} onClick={() => loadDataForItem(item)} className="cursor-pointer">
              <img src={item.imageUrl} alt={item.name} className="w-full h-24 object-cover" />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </Modal>

      {/* Modal Tambah Data */}
      <Modal isOpen={isAddDataModalOpen} title={isEditState ? "Edit Data" : "Tambah Data"} onClose={() => { setIsAddDataModalOpen(false); resetInput() }}>
        <form onSubmit={handleAddDataSubmit}>
          <div className="mb-4">
            <label htmlFor="inputtanggal" className="block text-sm font-medium text-gray-700">Tanggal</label>
            <input
              type="date"
              id="inputtanggal"
              value={inputTanggal}
              onChange={(e) => setInputTanggal(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              disabled={isEditState}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="inputLowest" className="block text-sm font-medium text-gray-700">Lowest</label>
            <input
              type="text"
              id="inputlowest"
              value={inputLowest}
              onChange={(e) => setInputLowest(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="inputpossible" className="block text-sm font-medium text-gray-700 flex items-center">
              Possible laku
              <span className="ml-2 relative group flex items-center justify-center w-5 h-5 bg-gray-200 text-gray-600 rounded-full border border-gray-300">
                ?
                {/* Tooltip di atas */}
                <div className="absolute hidden group-hover:block w-max max-w-xs bg-gray-700 text-white text-sm rounded py-2 px-4 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                  Kosongi kalo gatau atau gak jualan
                </div>
              </span>
            </label>
            <input
              type="text"
              id="inputpossible"
              value={inputPossible}
              onChange={(e) => setInputPossible(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="eventCheckbox" className="flex items-center text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                id="eventCheckbox"
                checked={checkBoxEvent}
                onChange={(e) => setCheckboxEvent(e.target.checked)}
                className="mr-2"
              />
              Event
            </label>
          </div>
          {checkBoxEvent && (
            <div className="mb-4 relative">
              <label htmlFor="inputevent" className="block text-sm font-medium text-gray-700 flex items-center">
                Keterangan
                <span className="ml-2 relative group flex items-center justify-center w-5 h-5 bg-gray-200 text-gray-600 rounded-full border border-gray-300">
                  ?
                  {/* Tooltip di atas */}
                  <div className="absolute hidden group-hover:block w-max max-w-xs bg-gray-700 text-white text-sm rounded py-2 px-4 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                    Event apa tuh?
                  </div>
                </span>
              </label>
              <input
                type="text"
                id="inputevent"
                value={inputEvent}
                onChange={(e) => setInputEvent(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
          )}
          <button type="submit" className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Submit</button>
        </form>
      </Modal>

      <div className="flex space-x-6">
        {/* Selected Item Chart */}
        {chartData && (
          <div className="bg-white p-4 rounded-lg shadow-md flex-1">
            {/* Wrapper untuk label */}
            <div className="flex justify-end space-x-4 mb-4">
              {/* Label pertama */}
              <div className="flex items-center space-x-2">
                <span
                  className="w-4 h-4"
                  style={{ backgroundColor: "rgb(75, 192, 192)" }}
                ></span>
                <span className="text-sm text-gray-700">lowest</span>
              </div>
              {/* Label kedua */}
              <div className="flex items-center space-x-2">
                <span
                  className="w-4 h-4"
                  style={{ backgroundColor: "rgb(50, 100, 150)" }}
                ></span>
                <span className="text-sm text-gray-700">possible laku</span>
              </div>
            </div>
            <PriceChart data={chartData} />
          </div>
        )}

        {/* Data Table */}
        {selectedItemData && (
          <div className="bg-white p-4 rounded-lg shadow-md w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Data Table</h2>
              <button 
                onClick={handleAddDataClick} 
                className="relative overflow-hidden flex items-center space-x-2 bg-blue-500 text-white py-2 px-4 rounded-lg"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
                </svg>
                <span>Tambah data</span>
                <span className="absolute inset-0 bg-white opacity-25 rounded-full transform scale-0 transition-transform duration-500 pointer-events-none"></span>
              </button>
            </div>
            <DataTable data={selectedItemData} onEditClick={handleEditTableClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
