import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBuuN5RnUCr1H-Fgd1ELLExBpk-uK0Amg4",
  // authDomain: "my-first-firebase-app-e7f5c.firebaseapp.com",
  authDomain: "night-crows.faynaldev.my.id",
  projectId: "my-first-firebase-app-e7f5c",
  storageBucket: "my-first-firebase-app-e7f5c.firebasestorage.app",
  messagingSenderId: "615170258069",
  appId: "1:615170258069:web:36b1a90673e375089d179c",
  measurementId: "G-EWBVFV0SQQ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const provider = new GoogleAuthProvider();
const auth = getAuth;

export { db, auth, provider };